import React, { Component } from 'react'
import Layout from 'Components/Layout'
import AppStore from 'AppStore'
import AppActions from 'AppActions'
import AppConstants from 'AppConstants'
import SubNav from 'Components/SubNav'
import assign from 'object-assign'
import styles from './index.module.scss'
import SubPage from 'Components/MyAccount/SubPage'
import { Wrapper } from 'Components/MyAccount/SubPage'
import OrderRow from './OrderRow'
import { formatDate, cancelablePromise } from 'Components/Utils/helpers'
import { WARNING } from 'Components/UI/Notifications'
import Price from 'Components/Utils/FormatPrice'
require('@gouch/to-title-case')

class OrderHistory extends SubPage {
  state = {
    customer: AppStore.getCustomer(),
    orders: []
  }
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    if (this.ordersFetch) this.ordersFetch.cancel()
    const customer = AppStore.getCustomer()
    this.ordersFetch = cancelablePromise(fetch(`${AppConstants.API_URL}orders-by-customer`, assign(AppConstants.POST_OPTIONS, { body: JSON.stringify({ id: customer.id, email: customer.email }) })))
    this.ordersFetch.promise
    .then((res) => res.json())
    .then((data) => {
      const error = data.type && data.type === 'error' ? true : false
      if (error) {
        setTimeout(AppActions.openNotification, 0, { type: WARNING, message: data.message })
      } else {
        this.setOrdersData(data)
      }
    })
    .catch(() => {
      setTimeout(AppActions.openNotification, 0, { type: WARNING, message: `Order's data unavailable, please refresh page` })
    })
    super.componentDidMount()
  }
  componentWillUnmount() {
    if (this.ordersFetch) this.ordersFetch.cancel()
    super.componentWillUnmount()
  }
  setOrdersData = (data) => {
    const orders = data.map(item => {
      return {
        shipment: item.data.meta_data[0].value[0],
        order: item.id,
        date: formatDate(item.data.date_created.date),
        status: item.data.status.toTitleCase(),
        total: Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, item.data.total ),
        subtotal: Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, item.data.total ),
        shippingCost: Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, 0 ),
        total: Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, item.data.total ),
        products: item.items.map((p) => {
          return {
            item: p.title,
            color: p.color.name.toTitleCase(),
            price: Price.format( AppConstants.LOCALE, AppConstants.CURRENCY, p.price ),
          }
        }),
        shipping: {
          country: item.data.shipping.country,
          firstName: item.data.shipping.first_name,
          lastName: item.data.shipping.last_name,
          companyName: item.data.shipping.company,
          address: item.data.shipping.address_1,
          additionalAddress: item.data.shipping.address_2,
          city: item.data.shipping.city,
          state: item.data.shipping.state,
          zipCode: item.data.shipping.postcode,
          phoneNumber: item.data.billing.phone,
          method: 'Card Payment with Stripe'
        },
        billing: {
          country: item.data.billing.country,
          firstName: item.data.billing.first_name,
          lastName: item.data.billing.last_name,
          companyName: item.data.billing.company,
          address: item.data.billing.address_1,
          additionalAddress: item.data.billing.address_2,
          city: item.data.billing.city,
          state: item.data.billing.state,
          zipCode: item.data.billing.postcode,
          phoneNumber: item.data.billing.phone
        }
      }
    })
    this.setState({ orders })
  }
  render() {
    return (
      <Layout>
        <Wrapper>
          <SubNav location={this.props.location} />
          <section className='u-col-lg-7 u-offset-lg-1'>
            <p className={`${styles.title} u-inline-block t-title-2`}>Order History</p>
            { this.state.orders.length === 0 &&
            <div className={`u-col-12 t-paragraph-1 u-pad-x--lg`}>
              There are no previous orders
            </div>
            }
            { this.state.orders.length >= 1 &&
            <div>
              <div className='u-row t-bg-lightgray t-title-1 u-pad-t--10 u-pad-b--10 u-pad-l--20 u-pad-r--20'>
                <p className='u-col-3 u-col-lg-2'>id</p>
                <p className='u-col-3 u-col-lg-3'>date</p>
                <p className='u-col-lg-2 u-d-none u-d-lg-inline-block'>status</p>
                <p className='u-col-3 u-col-lg-3'>total</p>
              </div>
              {
                this.state.orders.map((item, index) => {
                  const isLast = index === this.state.orders.length - 1
                  return (
                    <OrderRow {...item} index={index} key={`order-row-${index}`} isLast={isLast} />
                  )
                })
              }
            </div>
            }
          </section>
        </Wrapper>
      </Layout>
    )
  }
}

export default OrderHistory
