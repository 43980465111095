import React from 'react'
import Layout from 'Components/Layout'
import AppStore from 'AppStore'
import AppActions from 'AppActions'
import SubNav from 'Components/SubNav'
import styles from './index.module.scss'
import Checkbox from 'Components/UI/Checkbox'
import SubPage from 'Components/MyAccount/SubPage'
import { Wrapper } from 'Components/MyAccount/SubPage'

class Newsletters extends SubPage {
  constructor(props) {
    super(props)
    this.checkbox = React.createRef()
    this.state = {
      checked: true,
      customer: undefined
    }
  }
  componentDidMount() {
    setTimeout(() => {
      const customer = AppStore.getCustomer()
      this.setState({
        checked: customer ? customer.subscribe_to_newsletters : true,
        customer
      })
    }, 200)
    super.componentDidMount()
  }
  updateCustomer = () => {
    const customer = {
      ...this.state.customer,
      subscribe_to_newsletters: this.state.checked
    }
    setTimeout(AppActions.updateCustomer, 0, customer)
  }
  onChange = () => {
    this.setState({ checked: this.checkbox.current.state.checked  }, this.updateCustomer)
  }
  render() {
    return (
      <Layout>
        <Wrapper className={`${styles.parent}`}>
          <SubNav location={this.props.location} />
          <section className='u-col-lg-7 u-offset-lg-1'>
            <p className='u-inline-block t-title-2 u-pad-b--spacing--small'>Subscription</p>
            <Checkbox
              id='newsletter'
              className='t-title-1'
              value='newsletter'
              onChange={this.onChange}
              label='Receive our newsletters'
              ref={this.checkbox}
              checked={this.state.checked}
            />
            <div className='u-height--10' />
            <p className='t-paragraph-1'>Always be updated about our new products.</p>
          </section>
        </Wrapper>
      </Layout>
    )
  }
}

export default Newsletters
