import React, { Component } from 'react'
import MainBtn from 'Components/UI/MainBtn'
import styles from './index.module.scss'

class OrderRow extends Component {
  constructor(props) {
    super(props)
    this.panel = React.createRef()
    this.state = {
      open: this.props.open ? this.props.open : false
    }
  }
  componentDidMount() {
    this.setupPanel()
  }
  setupPanel = () => {
    if (this.state.open) {
      this.panel.current.style.maxHeight = `${this.panel.current.scrollHeight}px`
      this.panel.current.style.opacity = 1
    } else {
      this.panel.current.style.maxHeight = null
      this.panel.current.style.opacity = 0
    }
  }
  onClick = () => {
    this.setState({
      open: !this.state.open
    }, this.setupPanel)
  }
  render() {
    return (
      <div className={`${this.state.open ? styles.isActive : ''}`}>
        <div className={`${styles.parent} ${this.props.isLast ? styles.parentLast : ''} u-row t-paragraph-0 u-pad-l--20 u-pad-r--20 u-pad-t--10 u-pad-b--10`}>
          <p className='u-col-3 u-col-lg-2'>#{this.props.order}</p>
          <p className='u-col-3 u-col-lg-3'>{this.props.date}</p>
          <p className='u-col-lg-2 u-d-none u-d-lg-inline-block'>{this.props.status}</p>
          <p className='u-col-3 u-col-lg-3'>{this.props.total}</p>
          <div className='u-col-3 u-col-lg-2' onClick={this.onClick}>
            <button className='u-fit-w u-pad--small t-bg-black t-paragraph-0 t-color-white u-text-center t-bold'>
              {this.state.open ? 'Close' : 'View'}
            </button>
          </div>
        </div>
        <div ref={this.panel} className={`${styles.panel} ${this.props.isLast ? styles.panelLast : ''}`}>
          <div className={`t-bg-lightgray u-pad-l--20 u-pad-r--20 u-pad-t--20 u-pad-b--20`}>
            {/* Shipment Info */}
            {this.props.shipment &&
              <div className='u-col-12 t-bg-lightgray t-title-1'>
                {/* <div className={`u-height--40`} /> */}
                <div className={``}>
                  <div className={`u-pad-y--small`}>Shipment Info</div>
                  <div className={`t-paragraph-0`}>Tracking Number: {this.props.shipment.tracking_number}</div>
                  <div className={`t-paragraph-0`}>Tracking Provider: {this.props.shipment.custom_tracking_provider}</div>
                  <div className={`u-height--10`} />
                  <MainBtn
                    color='black'
                    text='Track your Order'
                    lineMargin={4}
                    url={this.props.shipment.custom_tracking_link}
                  />
                </div>
              </div>
            }
            <div className={`u-height--only-lg--20`}/>
            <div className={`u-height--lg--20`}/>
            {/* Items Info */}
            <div className='u-row t-bg-lightgray t-title-1'>
              <p className='u-col-3 u-col-lg-2 u-text-left u-pad-y--small'>items</p>
            </div>
            {
              this.props.products.map((product, index) => (
                <div className='u-row t-bg-lightgray t-paragraph-0' key={`product-row-${this.props.index}-${index}`}>
                  <div className='u-col-8 u-text-left'>
                    <div className={``}>{product.item} – ({product.color})</div>
                  </div>
                  <div className='u-col-4 u-text-center u-text-lg-right'>
                    <div className={`u-text-right`}>{product.price}</div>
                  </div>
                </div>
              ))
            }
            <div className={`u-height--only-lg--20`}/>
            <div className={`u-height--lg--30`}/>
            {/* Total Info */}
            <div className='u-row t-bg-lightgray'>
              <div className='u-col-lg-4 u-push-lg-8 u-text-lg-right'>
                <p className='t-title-1 u-pad-y--small'>total</p>
                <p className='t-paragraph-0'>
                  Subtotal: {this.props.subtotal}
                  <br />
                  Shipping Cost: {this.props.shippingCost}
                  <br />
                  Total: {this.props.total}
                </p>
              </div>
              <div className={`u-height--only-lg--20`}/>
              <div className='u-col-lg-4 u-pull-lg-4'>
                <p className='t-title-1 u-pad-y--small'>billing address</p>
                <p className='t-paragraph-0'>
                  {this.props.billing.firstName} {this.props.billing.lastName}
                  <br />
                  {this.props.billing.address}
                  <br />
                  {this.props.billing.city}, {this.props.billing.zipCode} {this.props.billing.country.name}
                  <br />
                  Phone: {this.props.billing.phoneNumber}
                </p>
              </div>
              <div className={`u-height--only-lg--20`}/>
              <div className='u-col-lg-4 u-pull-lg-4'>
                <p className='t-title-1 u-pad-y--small'>shipping address</p>
                <p className='t-paragraph-0'>
                  {this.props.shipping.firstName} {this.props.shipping.lastName}
                  <br />
                  {this.props.shipping.address}
                  <br />
                  {this.props.shipping.city}, {this.props.shipping.zipCode} {this.props.shipping.country.name}
                  <br />
                  Phone: {this.props.shipping.phoneNumber}
                  <br />
                  <br />
                  Method: {this.props.shipping.method}
                </p>
              </div>
              <div className={`u-height--20`} />
              <div className={`u-height--only-lg--20`}/>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default OrderRow
