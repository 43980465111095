import React from 'react'
import Layout from 'Components/Layout'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import WindowStore from 'WindowStore'
import SubNav from 'Components/SubNav'
import titles from 'Components/UI/Misc/titles'
import styles from './index.module.scss'
import FormHeader from 'Components/UI/FormHeader'
import Form from 'Components/UI/Form'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import SubPage from 'Components/MyAccount/SubPage'
import { Wrapper } from 'Components/MyAccount/SubPage'
require('@gouch/to-title-case')

class Details extends SubPage {
  state = {
    isEditingDetails: false,
    isEditingPassword: false,
    customer: AppStore.getCustomer(),
    detailsForm: [],
    passwordsError: ''
  }
  constructor(props) {
    super(props)
    this.passwordsForm = [
      {
        type: 'input',
        props: {
          id: 'newPassword',
          className: 'u-col-12',
          type: 'password',
          placeholder: 'New password',
          required: true
        }
      },
      {
        type: 'input',
        props: {
          id: 'confirmNewPassword',
          className: 'u-col-12',
          type: 'password',
          placeholder: 'Confirm new password',
          required: true
        }
      }
    ]
  }
  componentDidMount() {
    super.componentDidMount()
    this.setupForms()
    AppStore.on(AppConstants.UPDATE_CUSTOMER, this.onUpdateCustomer)
  }
  componentWillUnmount() {
    super.componentWillUnmount()
    AppStore.off(AppConstants.UPDATE_CUSTOMER, this.onUpdateCustomer)
  }
  setupForms() {
    const { first_name, last_name, email } = this.state.customer
    let title = titles.filter(title => title.value === this.state.customer.title)
    title = WindowStore.getViewType() === AppConstants.DESKTOP ? title : title[0]
    const detailsForm = [
      [
        {
          type: 'select',
          props: {
            id: 'genderTitle',
            className: 'u-col-4 u-col-lg-2 u-mrg-t--20',
            options: titles,
            placeholder: 'Title',
            value: title,
            required: true
          }
        },
        {
          type: 'input',
          props: {
            id: 'firstName',
            className: 'u-col-lg-9 u-offset-lg-1',
            type: 'text',
            placeholder: 'First Name',
            value: first_name,
            required: true
          }
        },
        {
          type: 'input',
          props: {
            id: 'lastName',
            className: 'u-col-lg-12',
            type: 'text',
            placeholder: 'Last Name',
            value: last_name,
            required: true
          }
        }
      ],
      {
        type: 'input',
        props: {
          id: 'mail',
          className: 'u-col-12',
          type: 'email',
          placeholder: 'Enter your mail',
          value: email,
          required: true
        }
      }
    ]
    this.setState({
      detailsForm
    })
  }
  onUpdateCustomer = (data) => {
    if (data.state === 'success') {
      this.setState({
        customer: AppStore.getCustomer(),
        passwordsError: ''
      }, this.setupForms)
    } else {
      this.setState({
        passwordsError: data.message
      })
    }
  }
  onDetailsSubmit = () => {
    const customer = {
      ...this.state.customer,
      title: this.refs.detailsForm.refs.genderTitle.state.value.value,
      first_name: this.refs.detailsForm.refs.firstName.state.value,
      last_name: this.refs.detailsForm.refs.lastName.state.value,
      email: this.refs.detailsForm.refs.mail.state.value
    }
    AppActions.updateCustomer(customer)
    this.setState({ isEditingDetails: false })
  }
  onEditDetails = () => {
    this.setState({ isEditingDetails: !this.state.isEditingDetails })
  }
  onPasswordSubmit = () => {
    const newPassword = this.refs.passwordsForm.refs.newPassword.state.value
    const confirmNewPassword = this.refs.passwordsForm.refs.confirmNewPassword.state.value
    if(newPassword !== confirmNewPassword) {
      this.refs.passwordsForm.refs.newPassword.setCustomValidity(`Passwords don't match`)
      this.refs.passwordsForm.refs.confirmNewPassword.setCustomValidity(`Passwords don't match`)
      return
    }
    const customer = {
      ...this.state.customer,
      newPassword,
      confirmNewPassword
    }
    AppActions.updateCustomer(customer)
    this.setState({ isEditingPassword: false })
  }
  onEditPassword = () => {
    this.setState({ isEditingPassword: !this.state.isEditingPassword })
  }
  render() {
    const { title, first_name, last_name, email } = this.state.customer
    const { isEditingDetails, isEditingPassword, passwordsError } = this.state
    return (
      <Layout>
        <Wrapper className={`${styles.parent}`}>
          <SubNav location={this.props.location} />
          <section className="u-col-lg-7 u-offset-lg-1">
            <FormHeader title="account details" onClick={this.onEditDetails} editing={isEditingDetails} />
            { isEditingDetails ?
            <Form items={this.state.detailsForm} submit={this.onDetailsSubmit} ref="detailsForm">
              <div className="u-row u-pad-t--spacing--small">
                <BackgroundBtn
                  className="u-col-lg-4"
                  text="Save"
                  type="submit"
                  fullBlack
                />
              </div>
            </Form> :
            <div className="t-paragraph-1">
              { title && <p>{[title.toTitleCase()+'.', first_name, last_name].join(' ')}</p>}
              <p>{email}</p>
            </div>
            }
            <div className="u-separator-pad"/>
            <FormHeader title="password" onClick={this.onEditPassword} editing={isEditingPassword} />
            { isEditingPassword ?
              <Form items={this.passwordsForm} submit={this.onPasswordSubmit} ref="passwordsForm">
                <div className="u-row u-pad-t--spacing--small">
                  <BackgroundBtn
                    className="u-col-lg-4"
                    text="Save"
                    type="submit"
                    fullBlack
                  />
                </div>
              </Form> :
              <p className="t-paragraph-1">************</p>
            }
            <div className="u-separator-pad"/>
          </section>
        </Wrapper>
      </Layout>
    )
  }
}

export default Details
