import React from 'react'
import BillingShippingForm from 'Components/UI/BillingShippingForm'
import Layout from 'Components/Layout'
import SubNav from 'Components/SubNav'
import SubPage from 'Components/MyAccount/SubPage'
import { Wrapper } from 'Components/MyAccount/SubPage'
import styles from './index.module.scss'

class Addresses extends SubPage {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <Layout>
        <Wrapper className={`${styles.parent}`}>
          <SubNav location={this.props.location} />
          <section className='u-col-lg-7 u-offset-lg-1'>
            <BillingShippingForm />
          </section>
        </Wrapper>
      </Layout>
    )
  }
}

export default Addresses
