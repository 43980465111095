import React from 'react'
import Layout from 'Components/Layout'
import AppStore from 'AppStore'
import AppConstants from 'AppConstants'
import AppActions from 'AppActions'
import SubNav from 'Components/SubNav'
import styles from './index.module.scss'
import ProductItem from 'Components/UI/ProductItem'
import SubPage from 'Components/MyAccount/SubPage'
import { Wrapper } from 'Components/MyAccount/SubPage'
import MainBtn from 'Components/UI/MainBtn'
import { navigate } from 'gatsby'

class Favorites extends SubPage {
  state = {
    products: [],
    customer: AppStore.getCustomer()
  }
  constructor(props) {
    super(props)
  }
  componentDidMount() {
    super.componentDidMount()
    AppStore.on(AppConstants.ADD_TO_FAVORITES, this.updateCurrentState)
    AppStore.on(AppConstants.REMOVE_FROM_FAVORITES, this.updateCurrentState)
    AppStore.on(AppConstants.MOVE_FAVORITES_TO_BAG, this.updateCurrentState)
    window.addEventListener('resize', this.resize)
    this.updateCurrentState()
  }
  componentWillUnmount() {
    AppStore.off(AppConstants.ADD_TO_FAVORITES, this.updateCurrentState)
    AppStore.off(AppConstants.REMOVE_FROM_FAVORITES, this.updateCurrentState)
    AppStore.off(AppConstants.MOVE_FAVORITES_TO_BAG, this.updateCurrentState)
    window.removeEventListener('resize', this.resize)
    super.componentWillUnmount()
  }
  updateCurrentState = () => {
    const products = []
    const currentProducts = AppStore.getFavorites()
    currentProducts.forEach(item => products.push(item.product))
    this.setState({ products }, this.resize)
  }
  resize = () => {
    this.productItemRefs.forEach(product => product.current.resize())
  }
  onFavoriteToBagClick = (item) => {
    setTimeout(AppActions.moveFavoritesToBag, 0, { product: item })
  }
  onProductItemRemoveClick = (product) => {
    setTimeout(AppActions.removeFromFavorites, 0, product)
  }
  render() {
    this.productItemRefs = []
    const products = this.state.products.map((product, index) => {
      const productRef = React.createRef()
      this.productItemRefs.push(productRef)
      return (
        <div key={`favorites-product-item---${product.id}-${index}`} className='u-col-6 u-col-lg-3'>
          <ProductItem
            baseUrl={AppConstants.CLOUDFRONT_CONTENT_URL}
            data={{product}}
            index={index}
            ref={productRef}
            extraClasses={'u-down-scale--1'}
            showFavoriteIcon={false}
            showRemoveIcon={true}
            showOtherColors={false}
            showInfo={false}
            lockSale={false} // Is always false because you need to be logged in to arrive here
            buttonText={'Add to bag'}
            pageToGo={product.permalink}
            buttonOnClick={this.onFavoriteToBagClick}
            removeOnClick={this.onProductItemRemoveClick}
            miniVersion={true}
          />
        </div>
      )
    })
    return (
      <Layout>
        <Wrapper>
          <SubNav location={this.props.location} />
          <section className="u-col-lg-7 u-offset-lg-1">
            <p className={`${styles.title} u-inline-block t-title-2`}>favorites list</p>
            { this.state.products.length === 0 &&
            <div className={`u-col-12 t-paragraph-1 u-pad-x--lg`}>
              <div>Your favorites list is empty</div>
              <div className='u-height--20' />
              <div className='u-height--only-lg--20' />
              <p className={`t-title-1 u-uppercase`}>
                Click the icon <span>
                  <svg className={`${styles.heartIcon} t-fill-color-black`}>
                    <use xlinkHref="#heart-icon" />
                  </svg>
                </span> on any product to save it to your Favourites
              </p>
              <div className='u-height--60' />
              <div className={`u-col-12 u-col-lg-12 u-uppercase`}>
                <MainBtn
                  color='black'
                  text='continue shopping'
                  onClick={() => { navigate('/') }}
                />
              </div>
            </div>
            }

            { this.state.products.length >= 1 &&
            <div className='u-row u-pad-x--lg'>{ products }</div>
            }

          </section>
        </Wrapper>
      </Layout>
    )
  }
}

export default Favorites
