import React, { Component } from 'react'
import AppStore from 'AppStore'
import AppActions from 'AppActions'
import styles from './index.module.scss'
import FormHeader from 'Components/UI/FormHeader'
import Checkbox from 'Components/UI/Checkbox'
import BackgroundBtn from 'Components/UI/BackgroundBtn'
import AddressForm from 'Components/UI/AddressForm'
import AppConstants from 'AppConstants'
import scrolltop from 'simple-scrolltop'
import { shippingIsSameAsBilling, getFormData, validateAddressForm } from 'Components/Utils/helpers'

class BillingShippingForm extends Component {
  constructor(props) {
    super(props)
    this.checkbox = React.createRef()
    this.billingAddressForm = React.createRef()
    this.shippingAddressForm = React.createRef()
    const customer = AppStore.getCustomer()
    this.state = {
      isShippingValid: validateAddressForm(customer.shipping),
      isBillingValid: validateAddressForm(customer.billing),
      isEditingBilling: this.props.isEditingBilling ? this.props.isEditingBilling : false,
      isEditingShipping: this.props.isEditingShipping ? this.props.isEditingShipping : false,
      customer,
      useShippingAsBilling: shippingIsSameAsBilling(customer)
    }
  }
  onBillingSubmit = () => {
    const p = this.billingAddressForm.current.form.current.refs
    const billing = getFormData(this.state.customer, p, 'billing')
    const customer = {
      ...this.state.customer,
      billing
    }
    setTimeout(AppActions.updateCustomer, 0, customer)
    this.setState({ isEditingBilling: false, customer, isBillingValid: validateAddressForm(customer.billing) }, this.billingAddressForm.current.setupForm)
    scrolltop(0)
  }
  onEditBilling = () => {
    this.setState({ isEditingBilling: !this.state.isEditingBilling })
  }
  onShippingSubmit = () => {
    const p = this.shippingAddressForm.current.form.current.refs
    const shipping = getFormData(this.state.customer, p, 'shipping')
    const customer = {
      ...this.state.customer,
      shipping
    }
    if (this.state.useShippingAsBilling) customer.billing = shipping
    setTimeout(AppActions.updateCustomer, 0, customer)
    this.setState({ isEditingShipping: false, customer, isShippingValid: validateAddressForm(customer.shipping) }, this.shippingAddressForm.current.setupForm)
    scrolltop(0)
  }
  onEditShipping = () => {
    this.setState({ isEditingShipping: !this.state.isEditingShipping })
  }
  onBillingCheckboxChange = () => {
    if (this.checkbox.current.state.checked) {
      const customer = { ...this.state.customer }
      customer.billing = customer.shipping
      setTimeout(AppActions.updateCustomer, 0, customer)
      this.setState({ isEditingShipping: false, customer, useShippingAsBilling: this.checkbox.current.state.checked }, this.shippingAddressForm.current.setupForm)
    } else {
      this.setState({ useShippingAsBilling: this.checkbox.current.state.checked })
    }
  }
  render() {
    const { isEditingBilling, isEditingShipping, isShippingValid } = this.state
    const shippingEditMode = isEditingShipping || !isShippingValid
    const showBilling = isShippingValid ? true : false
    const checkbox = (
      <Checkbox
        id='billingAsShipping'
        className='t-title-1'
        value=''
        onChange={this.onBillingCheckboxChange}
        label='Use this as your billing address'
        ref={this.checkbox}
        checked={this.state.useShippingAsBilling}
      />
    )
    return (
      <div className={styles.parent}>
        <FormHeader title="Shipping Address" onClick={this.onEditShipping} editing={shippingEditMode} />
        <AddressForm
          type={AppConstants.SHIPPING}
          ref={this.shippingAddressForm}
          submit={this.onShippingSubmit}
          isEditMode={shippingEditMode}
          data={this.state.customer.shipping}
        >
          <div className="u-row u-pad-t--spacing--small">
            <BackgroundBtn
              className="u-col-lg-4"
              text="Save shipping address"
              type="submit"
              highlighted
            />
          </div>
        </AddressForm>
        { isShippingValid &&
        <div>
          <div className='u-height--60' />
          { checkbox }
          <div className="u-separator-pad"/>
        </div>
        }
        { (!this.state.useShippingAsBilling && showBilling) &&
        <div>
          <FormHeader title="billing address" editTxt={`Edit`} onClick={this.onEditBilling} editing={isEditingBilling} />
          <AddressForm
            type={AppConstants.BILLING}
            ref={this.billingAddressForm}
            submit={this.onBillingSubmit}
            isEditMode={isEditingBilling}
            data={this.state.customer.billing}
          >
            <div className="u-row u-pad-t--spacing--small">
              <BackgroundBtn
                className="u-col-lg-4"
                text="Save billing address"
                type="submit"
                highlighted
              />
            </div>
          </AddressForm>
          <div className="u-separator-pad"/>
        </div>
        }
      </div>
    )
  }
}

export default BillingShippingForm
