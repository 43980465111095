import React, { Component } from 'react'
import { StaticQuery, graphql, navigate } from 'gatsby'
import Link from 'Components/Utils/Link'
import AppActions from 'AppActions'
import AppStore from 'AppStore'
import WindowStore from 'WindowStore'
import AppConstants from 'AppConstants'
import styles from './index.module.scss'
import find from 'lodash/find'
import Select from 'Components/UI/Select'
import Details from 'Components/MyAccount/Details'
import Favorites from 'Components/MyAccount/Favorites'
import OrderHistory from 'Components/MyAccount/OrderHistory'
import Addresses from 'Components/MyAccount/Addresses'
import Newsletters from 'Components/MyAccount/Newsletters'

class SubNav extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      customer: AppStore.getCustomer()
    }
  }
  componentDidMount() {
    setTimeout(() => {
      this.setState({ customer: AppStore.getCustomer() })
    }, 200)
  }
  onClick = () => {
    this.setState({ open: !this.state.open })
  }
  onLinkClick = () => {
    this.close()
  }
  close = () => {
    if (this.state.open) this.setState({ open: false })
  }
  onPageChange = ({value}) => {
    if (value.value === 'logout') setTimeout(AppActions.logout)
    else navigate(`/my-account/${value.value}`)
  }
  render() {
    return (
      <StaticQuery
        query={graphql`
          query {
            allAccountSubPagesJson {
              edges {
                node {
                  slug
                  name
                }
              }
            }
          }
        `}
        render={data => {
          const routes = data.allAccountSubPagesJson.edges.map(item => {
            const getComponent = (slug) => {
              if (slug === 'details') return Details
              else if (slug === 'favorites') return Favorites
              else if (slug === 'view-order') return OrderHistory
              else if (slug === 'addresses') return Addresses
              else return Newsletters
            }
            return {
              ...item.node,
              to: `/my-account/${item.node.slug}`,
              component: getComponent()
            }
          })
          const viewType = WindowStore.getViewType()
          const minimized = viewType === AppConstants.DESKTOP ? false : true
          const pageOptions = routes.map(item => { return { value: item.slug, label: item.name  } })
          let currentOption = { value: 'details', label: 'Account Details' }
          const customerIsLogged = AppStore.userIsValid(this.state.customer)
          const { location } = this.props
          let title = `Welcome`, currentRouteName = ''
          if (customerIsLogged) {
            const { first_name } = this.state.customer
            title = `Hello, <br/>${first_name}`
            const slug = location.pathname.replace('/my-account/', '').replace(/\//g, '')
            const currentRoute = find(routes, { slug })
            if (currentRoute && currentRoute.slug) currentOption = { value: currentRoute.slug, label: currentRoute.name }
            currentRouteName = currentRoute === undefined ? '' : currentRoute.name
            if (minimized) {
              pageOptions.push({
                value: 'logout',
                label: 'Logout'
              })
            }
          }
          return (
            <nav className={`${styles.parent} u-col-lg-2 u-offset-lg-1 u-z-index--1`}>
              <p className={`${styles.title} t-title-2 u-relative u-z-index--2`} dangerouslySetInnerHTML={{__html: title}}></p>
              { (customerIsLogged && minimized) &&
              <div className={`${styles.mobilePageSelector} u-offset-1 u-col-10 u-relative u-d-lg-none u-z-index--9980`}>
                <div className='u-height--80' />
                <Select
                  onChange={this.onPageChange}
                  withoutFeedback
                  placeholder={``}
                  value={currentOption}
                  options={pageOptions}
                />
                <div className='u-height--120' />
              </div>
              }
              { (customerIsLogged && !minimized) &&
              <div className={`${styles.wrapper} u-relative`}>
                <button
                  className={`t-title-1 u-relative u-uppercase u-fit-w u-text-center u-pad-y--small t-bg-lightgray t-border-black u-d-lg-none u-z-index--1`}
                  onClick={this.onClick}
                >
                  {currentRouteName}
                  <span className={`${styles.arrowIcon} ${this.state.open ? styles.isReversed : ''} u-absolute u-inline-block`}>
                    <svg className='u-fit'>
                      <use xlinkHref='#arrow-icon' />
                    </svg>
                  </span>
                </button>
                <div className={`${styles.links} ${this.state.open ? styles.isActive : ''}`}>
                  <ul>
                  {
                    routes.map(route => {
                      const url = `/my-account/${route.slug}/`
                      const className = location.pathname.indexOf(route.slug) > -1 ?
                      'u-untouch u-d-none u-d-lg-inline-block' :
                      't-color-gray'
                      return (
                        <li className={`${className} t-title-1 u-pad-y--small`} key={`subnav-route-${route.slug}`}>
                          <Link to={url} onClick={this.onLinkClick}>{route.name}</Link>
                        </li>
                      )
                    })
                  }
                  </ul>
                  <button
                    className='u-uppercase u-fit-w u-pad-y--small'
                    onClick={event => {
                      event.preventDefault()
                      this.close()
                      setTimeout(AppActions.logout)
                    }}
                  >
                    log out
                  </button>
                </div>
              </div>
              }
            </nav>
          )
        }
      }/>
    )
  }
}

export default SubNav
